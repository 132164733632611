@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  
  background-color: #006633;
  
  margin: 0;
  font-family: "Poppins";
}
/* general navbar */
.header1 {
  background: #fff;
}

/* card scroll bar */
/* .scroll-bar{
  overflow-x: scroll;
  height: 10px;
  
} */
.only{
  column-gap: 1200px;
  
}
.reglink{
  text-decoration:underline;
}
.loglink{
  text-decoration: underline;
}
.header1 .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 5px 10px;
  display: flex;
  align-items: center; 
   justify-content: flex-end;
   column-gap: 60px; 
   --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
    border-radius: 8px;
   
}
.header1 a {
  color: white;
  text-decoration: none;
}
.pages{
  max-width: 1400px;
  /* padding: 20px;
  margin: 0 auto; */
}

/* profile navbar */
.header2{
  background-color: none;
}
.header2 .container{
  max-width: 1400px;
  margin: 0;
  padding: 5px 10px;
  display: flex;
  align-items: center; 
  color: white;
  
   justify-content: flex-end;
   
   column-gap: 60px;  
}
.header2 a {
  color:white;
  text-decoration: none;
 
  padding: 6px;
  border-radius: 12px;
}

/* homepage */
.Home {
  
  gap: 100px;
  
}
.contains{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 10px;
  
}
 .workout-details {
  
  color: black;
 
 
   height: auto;
   
   padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  
  position: relative;
   box-shadow: 1px 1px 1px rgba(0,0,0,0.8); 
  
  border-radius: 14px;
}
.excluded > .navbar{
  display: none;
}
.circle{
  width: 32px;
  height: 32px;
  border-radius: 50%;  /* Creates the circle */
  background-color: rgba(0, 123, 255, 0); /* Starts with transparent color */
  transition: background-color 0.3s ease; 
}



.workout-details h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  
  color: black;
}
.workout-details span {
  position: absolute;
  bottom: 0px;
  left: 60px;
  cursor: pointer;
 
  padding: 6px;
  border-radius: 20%;
  
}
.workout-details .span2{
  left: 12px;
  bottom:0px;
  position: absolute;
}

label,input{
  display: block;
}
input{
  padding: 10px;
  
  font-family: "Poppings";
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-top: 10px;
  box-sizing: border-box;
  background-color: white;
  color: black;
}
form button {
  background:white;
  border: 0;
  color: #333;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

.contains{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 20px;
  
}


/* ProfileTabs.css */

.active-tab {
  border-bottom: 2px solid white;
 }


 .chat-window {
  height: 80vh;               /* Adjust based on the layout */
  width: 100%;                /* Full width */
  overflow-y: auto;           /* Enable vertical scrolling */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  /* Start with messages at the bottom */
  padding: 20px;
  background-color: #f4f4f4;  /* Subtle background color */
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
                    /* Space between messages */
}
.thecontainer{
  cursor: pointer;
}
.trash{
  

   /* Position above the hovered item */

 
}









